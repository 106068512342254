import "./App.css";
import profile from './profile.png';

function App() {
  return (
    <div className="App w-screen h-screen bg-stone-950 flex items-center justify-center flex-col">
      <img src={profile} alt="pfp" className="w-32 rounded-full" />
      <h1 className="text-white text-2xl font-semibold">yacoreq</h1>
      <br />
      <a href="https://youtube.com/@yacoreq" className="text-white text-xl bg-stone-900 p-3 rounded-md w-6/12 md:w-3/12 mb-5 transition-all hover:bg-blue-900"><i className="fa-brands fa-youtube"></i> YouTube</a>
      <a href="https://twitch.tv/Xyacoreq" className="text-white text-xl bg-stone-900 p-3 rounded-md w-6/12 md:w-3/12 mb-5 transition-all hover:bg-blue-900"><i className="fa-brands fa-twitch"></i> Twitch</a>
      <a href="https://instagram.com/yacoreq" className="text-white text-xl bg-stone-900 p-3 rounded-md w-6/12 md:w-3/12 mb-5 transition-all hover:bg-blue-900"><i className="fa-brands fa-instagram"></i> Instagram</a>
      <a href="https://tiktok.com/@yacoreq.tokox" className="text-white text-xl bg-stone-900 p-3 rounded-md w-5/12 md:w-3/12 mb-5 transition-all hover:bg-blue-900"><i className="fa-brands fa-tiktok"></i> TikTok</a>
    </div>
  );
}

export default App;
